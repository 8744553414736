<template>
  <v-btn class="mx-1" @click="buttonClick">
    <slot name="icon">
      <v-icon left dark class="mr-3"> mdi-clipboard-text-outline </v-icon>
    </slot>
    <slot>Copy</slot>
  </v-btn>
</template>

<script>
export default {
  name: "CopyButton",
  props: {
    /**
     * Attributes for the button
     * to: {
     *   type: String,
     *   required: true,
     *}
     */
    attributes: {},
  },
  methods: {
    buttonClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #da6028 !important;
  color: white;
}
</style>
