<template>
  <v-card-text>
    <v-card class="mx-auto">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="3">
              <dt>Media Name:</dt>
              <dd v-text="title"></dd>
            </v-col>
            <v-col cols="12" md="3">
              <dt>Media Domain:</dt>
              <dd v-text="domain"></dd>
            </v-col>
            <v-col cols="12" md="3">
              <dt>Media Type:</dt>
              <dd v-text="type"></dd>
            </v-col>
            <v-col cols="12" md="3">
              <dt>Live:</dt>
              <dd>
                <v-icon v-if="isActive" color="green">mdi-check-circle</v-icon>
                <v-icon v-else color="red">mdi-cancel</v-icon>
                {{ isActive ? "Yes" : "No" }}
              </dd>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto my-12">
      <section-header
        :target="() => this.$refs.mediaChannelCodeSnippetContainer"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              Code Snippet for Survey: {{ surveyName }}</v-col
            >
            <v-spacer></v-spacer>
            <copy-button
              @buttonClick="copySnippet"
              :disabled="isLoading"
            ></copy-button>
          </v-row>
        </v-container>
      </section-header>
      <v-card-text>
        <v-container fluid ref="mediaChannelCodeSnippetContainer">
          <v-sheet outlined class="px-2 py-2">
            <pre>{{ snippet }}</pre>
          </v-sheet>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto mt-12">
      <section-header :target="() => this.$refs.metricsContainer"
        ><v-container>
          <v-row>
            <v-col>Metrics</v-col>
          </v-row>
        </v-container></section-header
      >
      <v-card-text>
        <v-container fluid ref="metricsContainer">
          <v-row>
            <v-col cols="10" md="2">
              <dt># Started</dt>
              <dd v-text="started"></dd>
            </v-col>
            <v-col cols="10" md="2">
              <dt># Completed</dt>
              <dd v-text="completed"></dd>
            </v-col>
            <v-col cols="10" md="2">
              <dt># Qualified</dt>
              <dd v-text="qualified"></dd>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@admin/components/SectionHeader.vue";
import CopyButton from "@admin/components/action_buttons/CopyButton.vue";
export default {
  name: "MediaChannelView",
  components: { SectionHeader, CopyButton },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapFields("mediaChannels", {
      completed: "mediaChannel.submissionsCompleted",
      domain: "mediaChannel.domain",
      screenerId: "mediaChannel.screenerId",
      screenerName: "mediaChannel.screenerName",
      surveyName: "mediaChannel.surveyName",
      isActive: "mediaChannel.isActive",
      qualified: "mediaChannel.submissionsQualified",
      snippet: "mediaChannel.snippet",
      sponsorId: "mediaChannel.sponsorId",
      sponsorName: "mediaChannel.sponsorName",
      started: "mediaChannel.submissionsStarted",
      title: "mediaChannel.title",
      type: "mediaChannel.type",
    }),
    ...mapGetters(["acceptRoles"]),
    showEditMediaChannelButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  data: () => {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("mediaChannels", [
      "getMediaChannel",
      "clearMediaChannels",
    ]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setScreenerBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    copySnippet() {
      navigator.clipboard.writeText(this.snippet);
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await Promise.all([this.getMediaChannel(this.id)]);
    this.isLoading = false;
    this.setTitleObject(this.title);

    await this.setScreenerBreadcrumb({
      id: this.screenerId,
      name: this.screenerName,
    });

    this.setSponsorBreadcrumb({
      id: this.sponsorId,
      name: this.sponsorName,
    });
    this.setBreadcrumbs();
    if (this.showEditMediaChannelButton) {
      this.setActionButtons([
        {
          attributes: {
            to: "/media-channels/edit/" + this.id,
          },
          component: "EditButton",
          index: 0,
        },
      ]);
    }
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
pre {
  overflow: auto;
}
</style>
